import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import Button from "src/components/button/button";
import Item from "src/components/item/item";
import { serviceUrls } from "../../config/config";
import { showAlert } from '../../components/microapp-bridge';
import Spinner from "src/components/spinner/Spinner";
import { styles } from '../../style';
import { ApiService } from "../../utils/apiService";
import { MealOption, MealOptions } from "../../config/constant";

const Home = () => {
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [option, setOption] = useState('');
  const [selected, setSelected] = useState('');
  const [disabled, setDisabled] = useState(true);
  const user = useSelector((state: any) => state.auth.employee);

  const handleOnChange = (e: any) => {
    const value: MealOption = e.target.value;
    setDisabled(false);
    setOption(MealOptions[value].name);
    setSelected(MealOptions[value].id);
  }

  function handleSubmit() {
    setDisabled(true);
    if (selected == null) {
      showAlert("Error", "Please select a meal option", "Close", () => { }, () => { });
      return;
    }

    const body = {
      "mealOption": `${option}`,
      "date": new Date().toISOString().slice(0, 10),
      "department": user.department,
      "team": user.team,
      "managerEmail": user.managerEmail
    };

    const successFn = (data: any) => {
      if (data) {
        setSuccess(true);
        navigate('/complete', {});
        window.location.reload();
      }
    };

    const errorFunc = (error: String) => {
      setSuccess(false);
      showAlert("Error submitting dinner request.", error, "Close", () => { }, () => { });
    };

    const loadingFunc = (isLoading: boolean) => {
      setIsLoading(isLoading);
    };

    ApiService.handleRequest(`${serviceUrls.dinnerRequest}`, 'POST', body, successFn, errorFunc, loadingFunc);
  }

  return (
    <Fragment>
      {isLoading && <div className={`w-full top-0 left-0 right-0 bottom-0 h-screen bg-gray-700 opacity-75 bg-blend-darken inset-0 z-10 ${styles.divCenter} fixed`}><Spinner /></div>}

      {user.firstName == null ? <div className={`h-[80vh] text-center ${styles.divCenter}`}><Spinner /></div> :
        <div>
          <section className="text-sm px-2 lg:px-4 py-4 mx-auto bg-white border border-gray-200 rounded">
            <span className="uppercase text-[0.7rem] text-primary tracking-wide">{user.firstName} {user.lastName}</span>
            <div className="flex text-[0.7rem] mt-2 font-extralight text-slate-500 tracking-wide">
              <div className="capitalize-first mr-1">{user.team}</div>
              <div className="capitalize-first">({user.department})</div>
            </div>
            <div className="text-[0.7rem] font-extralight text-slate-500 tracking-wide">Lead: {user.managerEmail}</div>
          </section>
          <div className="mt-8 px-1 break-inside relative overflow-hidden flex flex-col justify-between space-y-3 text-sm rounded-xl mb-4 bg-white text-black">
            <div className="flex flex-row items-center space-x-3">
              <span className="text-base font-medium">Select a meal option</span>
            </div>
            <div className="text-[12px] font-extralight tracking-wide">Dinner request option is only available till 7.00pm for the given day.</div>
          </div>

          <Item selected={selected} onChange={(e: any) => handleOnChange(e)}></Item>
          <Button disabled={disabled} onClick={handleSubmit} name="Save"></Button>
        </div>
      }
    </Fragment>
  )
};

export default Home;
