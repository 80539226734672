import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import { showAlert, showConfirmAlert } from '../../components/microapp-bridge';
import { styles } from "../../style";
import { serviceUrls } from "../../config/config";
import { ApiService } from "../../utils/apiService";

const Complete = () => {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const mealRequest = useSelector((state: any) => state.meal.meal);
            
  const handleSubmit = () => {
    const successFn = (data: any) => {
      if (data) {
        setIsLoading(true);
        navigate('/', {});
        window.location.reload();
      }
    };
  
    const errorFunc = (error: String) => {
      showAlert("Error", "Error while cancelling dinner request", "Close", () => { }, () => { });
    };
  
    const loadingFunc = (isLoading: boolean) => {
      setIsLoading(isLoading);
    };
  
    showConfirmAlert("Cancel Order", "Are you sure you want to cancel your order?", "Yes", "No", () => {
      ApiService.handleRequest(`${serviceUrls.dinnerRequest}`, 
      'DELETE', null, successFn, errorFunc, loadingFunc)});
  }

  return (
  <div className={`h-[80vh] text-center ${styles.divCenter}`}>
    <div>
      <svg
        width="800px"
        height="800px"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="#ff7300"
        className="bi bi-check-circle-fill h-16 mb-4"
      >
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
      <div className="tracking-wider font-bold">Order Completed!</div>
      <div className="text-[0.8rem] mt-2 font-extralight tracking-wider">
        {"Dinner will be provided on " + new Date().toISOString().slice(0, 10) + "."}
        <br/>
        <span className="font-extralight tracking-wider">{"You ordered "}</span>
        <span className="text-primary">{mealRequest.mealOption}</span>
        <span>.</span>
      </div>
      <div onClick={handleSubmit} className="absolute font-extralight text-xs text-primary bottom-0 m-auto left-0 right-0 cursor-pointer tracking-wide">
        Do you wish to cancel your order?
      </div>
    </div>
  </div>
  )
};

export default Complete;
