import { Fragment, useEffect } from "react";
import { styles } from "../../style";
import { MealOptions } from "../../config/constant";

const Item = ({ onChange, selected }: any) => {
  useEffect(() => {
  }, [selected]);
  
  return (
  <Fragment>
    <div className={`grid ${styles.gridCol}`}>
      {Object.values(MealOptions).map((meal, index) => (
        <label
          htmlFor={meal.id}
          key={index}
          className={`${selected === meal.id ? 'bg-gray-200' : ''} hover:bg-gray-200 items-center p-3 rounded focus:bg-violet-700 border-solid border-[1.2px] border-slate-200 flex cursor-pointer`}
        >
          <input
            id={meal.id}
            value={meal.id}
            checked = {selected === meal.id}
            type="radio"
            className="mr-3 w-0 h-0 invisible"
            onChange={onChange}
          />
          <div className="tracking-wide text-[0.7rem] font-extralight text-black">{meal.name}</div>
          <img src={meal.image} alt="meal" className="ml-auto h-8 tracking-wide" />
        </label>
      ))}
    </div>
  </Fragment>
)};

export default Item;