import { styles } from "../../style";

const Hold = () => {
  return (
    <div className={`h-[80vh] text-center ${styles.divCenter}`}>
      <div>
        <img src="no-meal.svg" alt="meal" className="m-auto h-20" />
        <div className="text-[0.9rem] mt-6 font-extralight tracking-wider">{"You cannot make dinner requests past 7.00pm on " + new Date().toISOString().slice(0, 10)}</div>
      </div>
    </div>
  )
};

export default Hold;
