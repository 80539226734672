import { createSlice, PayloadAction, configureStore } from '@reduxjs/toolkit';
import { EmployeeState, Employee } from '../../models/types';

const initialState: EmployeeState = {
    employee: {} as Employee,
    status: 'idle',
    error: null
};


const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setEmployeeData(state, action:PayloadAction<Employee>) {
            state.employee = action.payload;
        }
    }
});

export default auth.reducer;

export const { setEmployeeData } = auth.actions;

export const store = configureStore({
    reducer: {
        auth: auth.reducer
    },
})