import { BasicUserInfo, HttpMethod, useAuthContext } from "@asgardeo/auth-react";

const MAX_TRIES = 2;

export class ApiService {
    private static _apiInstance: ApiService;
    private _getIdToken: (() => Promise<string>) | null = null;
    private _refreshAccessToken: (() => Promise<BasicUserInfo>) | null = null;

    private constructor(
        getIdToken: (() => Promise<string>) | null,
        refreshAccessToken: (() => Promise<BasicUserInfo>) | null,
        signIn: () => void
    ) {
        this._getIdToken = getIdToken;
        this._refreshAccessToken = refreshAccessToken;
    }

    public static setApiInstance(getIdToken: () => Promise<string>,
        refreshAccessToken: (() => Promise<BasicUserInfo>) | null,
        signIn: () => void) {
        ApiService._apiInstance = new ApiService(getIdToken, refreshAccessToken, signIn);
    }

    public static getApiInstance(): ApiService {
        if (!this._apiInstance) {
            throw new Error("API instance not set.");
        }
        return this._apiInstance;
    }

    public static handleRequest = async (url: string, method: HttpMethod, body: Object | null, successFn: (param: any) => void,
        failFn: (param: any) => void, loadingFn: (param: any) => void, headers?: HeadersInit | null, currentTry?: number | null) => {
        var tries: number = Boolean(currentTry) ? currentTry as number : 0;
        console.log("Tries: ", tries, Boolean(currentTry));
        try {
            if (loadingFn) {
                loadingFn(true);
            }

            var encodedUrl = encodeURI(url);
            var bearerToken: string = "Bearer "+ await ApiService._apiInstance?._getIdToken?.();
            ;
            const response = await fetch(encodedUrl, {
                method: method,
                body: body ? JSON.stringify(body) : null,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': bearerToken,
                    // 'x-jwt-assertion': getAccessToken()
                }
            });

            let responseBody: any;

            // Assumptions
            // The only reason we may get 403 responses from Gateway is due to user input validation issues (rules blocking certain user inputs).
            // The other reasons may be due to code-level issues. But we assume they are already fixed after testing.
            // We only show the custom error msg for post / patch / put requests
            // We also assume that the gateway is sending a html page in response (therefore no json body and therefore handled in catch)
            try {
                responseBody = await response.json();
            } catch (e) {
            } finally {
                let customErrMsg = "";

                if (response.status === 200 || response.status === 202 || response.status === 201) {
                    successFn(responseBody);

                    if (loadingFn) {
                        loadingFn(false);
                    }
                } else {
                    if (response.status === 401 && tries < MAX_TRIES) {
                        // refreshAccessToken().then(() => handleRequest(encodedUrl, method, body, successFn, failFn, loadingFn, null, ++tries));
                    } else if (tries < MAX_TRIES) {
                        ApiService.handleRequest(encodedUrl, method, body, successFn, failFn, loadingFn, null, ++tries);
                    } else {
                        console.error((responseBody && responseBody.error) || response.status);
                        if (failFn) {
                            failFn(customErrMsg);
                        }
                        if (loadingFn) {
                            loadingFn(false);
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err);
            if (failFn) {
                failFn(err);
            }
            if (loadingFn) {
                loadingFn(false);
            }
        }
    }
};