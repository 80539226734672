import { createSlice, PayloadAction, configureStore } from '@reduxjs/toolkit';
import { MealState, Meal } from '../../models/types';

const initialState: MealState = {
    meal: {} as Meal,
    status: 'idle',
    error: null
};

const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        getMealData(state, action:PayloadAction<Meal>) {
            state.meal = action.payload;
        },
        setMealData(state, action:PayloadAction<Meal>) {
            state.meal = action.payload;
        }
    }
});

export default auth.reducer;

export const { getMealData, setMealData } = auth.actions;

export const store = configureStore({
    reducer: {
        auth: auth.reducer
    },
})