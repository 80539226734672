var idToken = null;
var userName = "";
var userRoles = [];
var userPrivileges = [];

var accessToken = null;

var isLoggedOut = false;

export function setIsLoggedOut(status) {
    isLoggedOut = status;
}

export function getIsLoggedOut() {
    return isLoggedOut;
}

export function setIdToken(token) {
    idToken = token;
}

export function getIdToken() {
    return idToken;
}

export function setUserName(user) {
    userName = user;
}

export function getUserName() {
    return userName;
}

export function setUserPrivileges(privileges) {
    userPrivileges = privileges;
}

export function getUserPrivileges() {
    return userPrivileges;
}

export function setUserRoles(rolesFromJWT) {
    if (typeof rolesFromJWT === 'string') {
        userRoles = rolesFromJWT.split(',');
    } else if (Array.isArray(rolesFromJWT)) {
        userRoles = rolesFromJWT.slice();
    }
}

export function getUserRoles() {
    return userRoles;
}

export function setAccessToken(token) {
    accessToken = token;
}

export function getAccessToken() {
    return accessToken;
}
