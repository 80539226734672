import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './store/index';
import CompletePage from './pages/complete/Complete';
import Form from './pages/form/Form';
import HoldPage from './pages/hold/Hold';
import MainLayout from './pages/Main';
import { AuthProvider, useAuthContext } from "@asgardeo/auth-react";
import { useEffect, useState } from "react";
import { PageLoader } from "./components/PageLoader";
import { asgardeoConfig, isMicroApp } from "./config/config";
import { Login } from "./pages/Login";
import { ApiService } from "./utils/apiService";
import { getToken } from "./components/microapp-bridge";
import { setAccessToken } from "./utils/oauth";

const router = createBrowserRouter([{
  path: '/',
  element: <MainLayout />,
  children: [
    {
      index: true,
      path: 'form',
      element: <Form />
    },
    {
      path: 'complete',
      element: <CompletePage />
    },
    {
      path: 'hold',
      element: <HoldPage />
    }
  ]
}
]);

const WebApp = () => {
  const { state, refreshAccessToken, getIDToken } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state.isAuthenticated && !state.isLoading) {
      refreshAccessToken().then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
    async function getIdToken(): Promise<string> {
      return getIDToken();
    };

    ApiService.setApiInstance(getIdToken, refreshAccessToken, () => { });
  }, []);

  return (
    <>
      {state.isLoading || isLoading ? <PageLoader /> : state.isAuthenticated ? <RouterProvider router={router} /> : <Login />}
    </>
  );
};

const MobileApp = () => {
  useEffect(() => {
    async function getIdToken(): Promise<string> {
      return new Promise<string>((resolve, reject) => {
        getToken((accessToken: string) => {
          setAccessToken(accessToken);
          resolve(accessToken);
        });
      });
    };

    ApiService.setApiInstance(getIdToken, null, () => { });
  }, []);

  return (
    <RouterProvider router={router} />
  );
};

const App = () => {
  return (
    <>
      {isMicroApp ?
        <Provider store={store}>
          <MobileApp />
        </Provider>
        :
        <AuthProvider config={asgardeoConfig}>
          <Provider store={store}>
            <WebApp />
          </Provider>
        </AuthProvider>}
    </>);
};

export default App;
