export type MealOption = "meal-01" | "meal-02" | "meal-03";

interface MenuItem {
  id: MealOption;
  name: string;
  image: string;
}

// Sample data
export const MealOptions: {
  "meal-01": MenuItem;
  "meal-02": MenuItem;
  "meal-03": MenuItem;
} = {
  "meal-01": {
    id: "meal-01", name: "Chicken", image: "meal-chicken.svg"
  },
  "meal-02": {
    id: "meal-02", name: "Fish", image: "meal-fish.svg"
  },
  "meal-03": {
    id: "meal-03", name: "Vegetarian", image: "meal-vegetarian.svg"
  }
}

export const SnackMessage = {
  error: {
    userDataRetrieval: "Try logging in again. If this issue persists please contact internal-apps team."
  }
}

export const INPUT_INVALID_MSG_GATEWAY = {};
