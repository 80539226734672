import { useAuthContext } from "@asgardeo/auth-react";
import { useState } from "react";
import logo from "../assets/images/wso2-logo-black.png";
import { PageLoader } from "../components/PageLoader";
import { appDescription, appName } from "../config/config";

export const Login = () => {
    const { signIn, state } = useAuthContext();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isSessionActive = localStorage.getItem("asgardeo-session-active") === "true";
    sessionStorage.setItem("redirect-path", window.location.pathname);

    return isSessionActive ? (
        <PageLoader />
    ) : (
        <div className="flex flex-col justify-center bg-white bg-no-repeat bg-center h-screen w-screen">
            <div className="w-2/6 m-auto">
                <div className="bg-white p-4 rounded-lg shadow-md">
                    <div className="p-1 bg-white">
                        <div className="flex flex-col -mx-2 p-4 items-center">
                            <div className="w-full p-2 items-center flex justify-center">
                                <img alt="logo" width="130" height="auto" src={logo} className="max-w-full max-h-full"></img>
                            </div>
                            <div className="w-full p-2 items-center">
                                <div className="text-center font-bold text-xl text-black">
                                    {appName}
                                </div>
                            </div>
                            <div className="w-full p-2 items-center">
                                <div className="text-center font-normal text-xs text-black">
                                    {appDescription}
                                </div>
                            </div>
                            <div className="w-full items-center flex p-2 justify-center">
                                <button
                                    onClick={() => {
                                        signIn();
                                        setIsLoading(true);
                                    }}
                                    className={`${isLoading || state.isLoading ? "cursor-wait" : "cursor-pointer"
                                        } bg-primary text-white font-bold py-2 px-4 rounded flex items-center justify-center ax-w-full max-h-full`}
                                    disabled={isLoading || state.isLoading}
                                >
                                    {isLoading || state.isLoading ? (
                                        <svg
                                            className="animate-spin h-5 w-5 mr-3 text-white"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            ></circle>
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8v8l5.656 5.656A8 8 0 014 12z"
                                            ></path>
                                        </svg>
                                    ) : (
                                        "Sign In"
                                    )}
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
