import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showAlert } from "../components/microapp-bridge";
import { setEmployeeData } from "src/store/reducers/auth";
import { setMealData } from "src/store/reducers/meal";
import { serviceUrls } from "../config/config";
import { SnackMessage } from "../config/constant";
import { ApiService } from "../utils/apiService";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const MainLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const redirectPath = sessionStorage.getItem("redirect-path");
  sessionStorage.removeItem("redirect-path");

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("form");
    }
  }, []);

  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mealRequest = useSelector((state: any) => state.meal.meal);

  const loadEmployeeData = () => {
    const successFn = (data: any) => {
      if (data) {
        dispatch(setEmployeeData(data));
      }
    };

    const errorFunc = (error: String) => {
      setSuccess(false);
      showAlert("Something went wrong.", SnackMessage.error.userDataRetrieval, "Close", () => { }, () => { });
    };

    const loadingFunc = (isLoading: boolean) => {
      setIsLoading(isLoading);
    };

    ApiService.handleRequest(`${serviceUrls.employee}`, "GET", null, successFn, errorFunc, loadingFunc);
  };

  const loadRequestData = () => {
    const successFn = (data: any) => {
      if (data) {
        dispatch(setMealData(data));
      }
    };

    const errorFunc = (error: String) => {
      setSuccess(false);
      showAlert("Something went wrong.", SnackMessage.error.userDataRetrieval, "Close", () => { }, () => { });
    };

    const loadingFunc = (isLoading: boolean) => {
      setIsLoading(isLoading);
    };

    ApiService.handleRequest(`${serviceUrls.dinnerRequest}`, "GET", null, successFn, errorFunc, loadingFunc);
  };

  const isValidTime = () => {
    var hour = new Date().getHours();
    if (hour >= 19 && hour <= 24) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setIsLoading(true);
    loadEmployeeData();
    loadRequestData();
  }, []);

  return (
    <div className="p-3">
      <Outlet />
    </div>
  );
};

export default MainLayout;
